.button {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: var(--corner-radus-global-cr-10099999, 99999px);
    background: linear-gradient(34deg, #385feb -13.6%, #8351f5 53.76%);

    &.small {
        height: 28px;
        font-size: 12px;
    }

    .icon {
        --ai-icon-bg: white;
    }

    &:before {
        // Hover
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        background: linear-gradient(34deg, #203686 -13.6%, #8351f5 53.76%);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:after {
        // Active
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(34deg, #2945ac -13.6%, #8351f5 53.76%);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover:before,
    &:active:after {
        opacity: 1;
    }

    &:disabled {
        background: #f8f8f8;
        color: #b4b4b4;

        .icon {
            --ai-icon-bg: #767676;
        }
    }

    .secondaryContainer {
        position: absolute;
        z-index: 0;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 40px;
        background-color: white;
        inset: 0;
        transform: translate(1px, 1px);
        transition: background-color 0.2s ease-in-out;
    }

    &.secondary {
        &:hover:before,
        &:active:after {
            opacity: 0;
        }

        &:hover {
            .secondaryContainer {
                background-color: #f5f7fe;
            }
        }

        .children {
            z-index: 1;
            background: linear-gradient(34deg, #2945ac -13.6%, #8351f5 53.76%);
            background-clip: text;
            color: transparent;
        }

        &.selected {
            .secondaryContainer {
                background: linear-gradient(34deg, #2945ac -13.6%, #8351f5 53.76%);
            }

            .children {
                color: white;
            }
        }
    }
}

%text-container {
    position: absolute;
    z-index: 1;
    display: flex;
    width: 400px;
    flex-direction: column;
    gap: 35px;
}

.contentContainer {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    .bgElements {
        position: absolute;
        z-index: 0;
        inset: 0;
        background: linear-gradient(256deg, #dde5ff 23.47%, #efe8ff 44.34%, #f0f3f9 61.46%);

        .textContainerTop {
            @extend %text-container;

            top: 5%;
            right: 35%;
        }

        .textContainerBottom {
            @extend %text-container;

            right: 8%;
            bottom: 5%;
        }
    }

    .content {
        position: relative;
        z-index: 1;
        display: flex;
        width: 60vw;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        gap: 10rem;

        .titleContainer {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            backdrop-filter: blur(1px);
            row-gap: 20px;
            text-shadow: 0 0 6px white;

            .title {
                max-width: 700px;
                font-size: 48px;
                font-weight: 500;
                line-height: 64px;
            }

            .description {
                max-width: 700px;
                margin-bottom: 30px;
            }

            .action {
                width: 220px;
                height: 40px;
            }
        }

        .videoContainer {
            position: relative;
            height: 50vh;
            padding: 1rem;
            border-radius: 17px;
            background: linear-gradient(258deg, rgb(237 241 255 / 50%) 12.79%, rgb(248 247 255 / 40%) 80.79%);
            filter: drop-shadow(0 12px 16px rgb(47 61 83 / 11%));
        }
    }
}

.bgTextElement {
    display: flex;
    width: fit-content;
    height: 45px;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 30px;
    background: linear-gradient(180deg, rgb(241 242 251 / 45%) 0%, rgb(250 250 255 / 70%) 100%);
    gap: 5px;

    &:first-child {
        align-self: flex-end;
    }

    .icon {
        display: flex;
        width: 58px;
        height: 29px;
        align-items: center;
        justify-content: center;
        border-radius: 58px;
        background: linear-gradient(103deg, #e8e6ff 16.47%, rgb(223 221 255 / 80%) 103.55%);
        color: #2f426c;
    }
}

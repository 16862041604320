.alertBannerContainer {
    margin: 24px 0;
    border-radius: 4px;
    min-height: 48px;
    max-height: 48px;
    border: 1px solid #afbff7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px 0 16px;
    &.warning {
        border-color: var(--wmWarning);
        background-color: #fef8e8;
    }
    &.error {
        border-color: #f6a2a2;
        background-color: #fdeaea;
    }
    .bannerText {
        font-family: var(--wmFontSecondary);
        font-size: 14px;
        font-weight: 400;
        color: var(--wmGrayUltraDark);
        padding-left: 8px;
    }
}

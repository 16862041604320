.button {
    display: flex;
    width: 264px;
    height: 30px;
    align-items: center;
    padding: 10px 17px;
    border: 1px solid #e6ecf8;
    border-radius: 4px;
    background-color: white;
    column-gap: 11px;
    cursor: pointer;
    transition: border-color 0.3s ease-out;
    font-family: var(--wmFontSecondary);

    &:disabled {
        background-color: black;
        cursor: default;
    }

    &:not(:disabled) {
        &.active,
        &:active {
            border-color: #385feb;
        }

        &:hover {
            border-color: #93a8f4;
        }
    }
}

.popover {
    width: 328px;
    min-height: 200px;
    transform: translateY(5px) !important;
    border-radius: 12px;
    box-shadow: 0px 36px 52px 0px rgba(47, 61, 83, 0.11);
}

.header {
    height: 42px;
    padding: 10px 25px 0;
    border-bottom: 1px solid #d8dffb;
    background-color: #f9fbfd;

    .indicator {
        bottom: -6px;
    }

    .tabContent {
        color: black;
    }

    .tab {
        opacity: 1;

        &:hover {
            .tabContent {
                color: #385feb;
            }
        }
    }
}

.content {
    padding: 16px 24px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 0 16px;
    column-gap: 20px;
}

%large {
    --size: 40px;

    border-radius: 40px;
}

%btn-disabled {
    background-color: #c5cad6;
    color: #fff;
}

.button {
    font-weight: 600;

    &.disabledStyle:disabled {
        @extend %btn-disabled;
    }

    &.large {
        @extend %large;
    }

    &.small {
        --size: 28px;
    }
}

.btnStyle {
    @extend %large;

    --size: 40px;

    &:disabled {
        @extend %btn-disabled;
    }
}

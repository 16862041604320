.flexRow {
    display: flex;
    flex-direction: row;
    &.spaceBetween {
        justify-content: space-between;
    }
    &.center {
        align-items: center;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    &.spaceBetween {
        justify-content: space-between;
    }
    &.center {
        align-items: center;
    }
}

.width100 {
    width: 100%;
}

@import './style.module.scss';

.pageHeader {
    font-family: var(--wmFontPrimary);
    font-size: 24px;
    color: var(--wmGrayUltraDark);
    font-weight: 600;
    line-height: 32px;
}

.tableSubHeader {
    font-size: 12px;
    font-weight: 400;
    height: 16px;
    line-height: 18px;
    color: #637191;
    font-family: var(--wmFontSecondary);
}
.dateRangeCustom {
    background-color: var(--wmWhite);
}

.selectCustom {
    width: 256px;
    max-width: 256px;
}

.AiHubDashBoardCard {
    margin-top: 24px;
    height: 100%;
}

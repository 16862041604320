.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 27px;
    border: 1px solid #e3e9fc;
    border-radius: 4px;
    background-color: white;
    color: #637191;
    cursor: pointer;
    font-family: var(--wmFontSecondary);
    font-size: 14px;
    transition: all 0.3s ease-out;

    &:hover {
        border-color: blue;
        color: black;
    }

    &.selected,
    &:active,
    &:focus {
        border-color: #385feb;
        background-color: #f4f6fb;
        color: #385feb;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 15px;

    .year {
        color: #637191;
        font-family: var(--wmFontSecondary);
        font-weight: 600;
    }
}

.yearBtn {
    background-color: transparent;
}

.leftBtn {
    transform: rotate(180deg);
}

.flexContainer {
    display: flex;

    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
    &.spaceBetween {
        justify-content: space-between;
    }
    &.center {
        align-items: center;
    }
}
